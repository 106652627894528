<template>
<Breadcrumb :urls="urls" :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
<WhatsAppButton></WhatsAppButton>
  <section class="getTouchBgsec" v-if="translationloaded==true">
    <div class="mainContainer">
      <div class="row">
        <div class="col-sm-12">
          <div class="getTouchTxtsec">
            <h3 class="subMainHeaderall">{{ translate('getInTouchWithUs')}}</h3>
            <p>             
              {{ translate('givUsACallOrDropByAnytime')}}
            </p>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="col-xl-4 col-lg-4 borderContact">
          <div class="getTouchTxtsec1">
            <i class="fa fa-home" aria-hidden="true"></i>
            <span>{{ translate('officeAddress')}}</span>
            <p>
            Al Khalidiya, Abu Dhabi, Behind Shining Towers
            </p>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 borderContact">
          <div class="getTouchTxtsec1">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <span>{{ translate('phone')}}</span>
            <p> +97126781234 & +971543888998</p>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 borderContact">
          <div class="getTouchTxtsec1">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            <span>{{ translate('email')}}</span>
            <p>info@khairatlebanon.com</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="contactFieldBgSec">
    <div class="mainContainer">
      <div class="row">
        <div class="col-sm-12">
          <div class="positionwuickLinkbg">
            <h3> {{ translate('letsKeepInTouch')}}</h3>

            <input  :placeholder="translate('name')" :class="{'contctInputStyle2':true,'error_border':contact_form.first_name_error}"  v-model="contact_form.first_name" type="text" />
            <input  :placeholder="translate('email')" :class="{'contctInputStyle2':true,'error_border':contact_form.email_error}"  v-model="contact_form.email" type="text" />
              <div style="display:flex; width: 100%; margin-right: 0px;">
                 <input type="text"  style="width:20%"  v-model="country_code" :class="{'contctInputStyle2':true}" />                          
                            <input  style="width:80%;  margin-right: 0px;" :placeholder="translate('mobile')" :class="{'contctInputStyle2':true,'error_border':contact_form.phone_error}"   v-model="contact_form.phone" type="text" />

                </div>  
          
            <textarea
              rows="2"
             :class="{'contctInputStyle3':true,'error_border':contact_form.message_error}"
              :placeholder="translate('EnterYourMessage')"
              v-model="contact_form.message"
            ></textarea>

           

                   <button  class="subMitBtnmain" type="button" @click="submitContactForm()">
                    <template v-if="cart_ajax =='closed'">
                        {{ translate('submit')}}
                    </template>
                    <template v-if="cart_ajax =='open'">
                    <ButtonLoader
                        :loaderColor="loaderColor"
                        :loaderStyle="loaderStyle"
                        :cart_ajax="cart_ajax"
                    >
                    </ButtonLoader>
                    </template>
                </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import ButtonLoader from "../components/ButtonLoader";
import helper from "@/helper/helper";
import {mapActions} from 'vuex';
import WhatsAppButton from "@/components/WhatsAppButton";
import Config from  '@/config';

// import $ from 'jquery';
export default {
  name: "ContactForm",
    components: {   
    Breadcrumb,
    ButtonLoader,
    WhatsAppButton

  },
    props:[
     'translationloaded',
     'translate'
  ],
  mounted(){
     this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;      
        this.country_code = Config.COUNTRY_CODE;     
      helper.backtoTop();
  },
      data(){
        return {
          'country_code':'',
                urls: [
                        {
                        path: "/",
                        name: "Home",
                        },
                        {
                        path: "/contact-form",
                        name: "contactUS",
                        },
                    ],
                    contact_form:{
                        "first_name":"",
                        "first_name_error":"",
                        "phone":"",
                        "phone_error":"",
                        "email":"",
                        "email_error":"",                       
                        "phone_error_msg":"",
                        "message":"",
                        "message_error":""
                    },
                    errorStatus:false,
                    cart_ajax:"closed",                     
                    loaderColor: "#fff",
                    loaderStyle: {
                        width: "35px",
                    },
        }
    },
    watch: {
       fetchCurrentLag(lang){
            this.language =lang;            
         }  
    },
    methods:{
        ...mapActions(['submitContactform']),
        submitContactForm(){
          
        
            if(this.contact_form.first_name==''){
                this.contact_form.first_name_error=true;
                this.errorStatus  =true;
            }else{
                 this.contact_form.first_name_error=false;
            }
            
            if(this.contact_form.phone==''){
                this.contact_form.phone_error=true;
                this.errorStatus  =true;
            }else{
                let a = /^\d{9}$/; 
                 if (a.test(this.contact_form.phone)) {
                      this.contact_form.phone_error=false;
                       this.contact_form.phone_error_msg='';
                 }else{
                      this.contact_form.phone_error=true;
                      this.contact_form.phone_error_msg='Invalid mobile number, Its length not matched';
                      this.errorStatus  =true;
                 }

                
            }

            if(this.contact_form.email==''){
                this.contact_form.email_error=true;
                 this.errorStatus  =true;
            }else{

                if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.contact_form.email)){
                    this.contact_form.email_error=false; 
                    this.contact_form.email_error_msg='';                   
                }else{
                    this.contact_form.email_error=true;
                     this.errorStatus  =true;
                     this.contact_form.email_error_msg='Please enter a valid email address.';
                }
                 
            }

             if(this.contact_form.message==''){
                this.contact_form.message_error=true;
               this.errorStatus  =true;
            }else{
                 this.contact_form.message_error=false;
            }

             if(this.errorStatus == false &&  this.cart_ajax=='closed'){
          
                        this.cart_ajax="open";
                        let $this =this;
                        let payload ={
                            "custId":localStorage.getItem('custId')??'',
                            "email":this.contact_form.email,
                            "mobile":this.contact_form.phone,
                            "title":`${this.contact_form.first_name}`,
                            "message":this.contact_form.message
                        };

                        $this.submitContactform(payload).then(function(response){
                         
                              if(response.status==200){                                
                                   $this.contact_form.first_name="";
                                   $this.contact_form.first_name_error="";
                                   $this.contact_form.phone="";
                                   $this.contact_form.phone_error="";
                                   $this.contact_form.email="";
                                   $this.contact_form.email_error="";
                                   $this.contact_form.phone_error_msg="";
                                   $this.contact_form.message="";
                                   $this.contact_form.message_error="";
                                   $this.errorStatus=false;
                                   $this.$toast.success(`${response.data.Message}`);
                              }else{
                                   $this.$toast.error(`Something went wrong please try again`);
                              }

                               $this.cart_ajax="closed";

                        }).catch(function(error){
                             $this.$toast.error(`${error.response.data.Message}`);
                             $this.cart_ajax="closed";
                        });
             }

         
        },
          
    }
};
</script>
<style scoped>
    .error_border{
        border: 1px solid red !important;
    }
</style>
